<template>
    <div>
        <div class="page-header hidden-sm-and-down">
            <el-page-header @back="$router.go(-1)" content="专家统计"/>
        </div>
        <div class="search" style="margin: 15px 0">
            <el-form :inline="true" :model="search" size="small">
                <el-form-item label="分类" prop="oriente">
                    <selectExpertCategory v-model="search.category"  :collapse="false"
                                          />
                </el-form-item>
                <el-form-item label="科室" prop="department">
                    <selectExpertDepartment v-model="search.department"  :collapse="false"
                                            />
                </el-form-item>
                <el-form-item label="专业" prop="major">
                    <selectExpertMajor v-model="search.major"  :collapse="false"
                                       />
                </el-form-item>
                <el-form-item label="职称" prop="title">
                    <selectExpertTitle v-model="search.title" :collapse="false"
                                       />
                </el-form-item>
                <el-form-item label="姓名">
                    <el-input v-model="search.name" placeholder="请输入姓名" clearable/>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="getData" size="small">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-row :gutter="10" style="margin:20px 0">
            <el-col :xs="24" :sm="12" :md="6">
                <el-card class="box-card" shadow="hover">
                    <div slot="header" class="clearfix">
                        <span>专家分类分布</span>
						<el-button style="float: right; padding: 3px 0" type="text" icon="el-icon-refresh" @click="getCategory"></el-button>
                    </div>
                    <category-bar :bardata="categoryList" keys="category"/>
                </el-card>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6">
                <el-card class="box-card" shadow="hover">
                    <div slot="header" class="clearfix">
                        <span>专家分类占比</span>
						<el-button style="float: right; padding: 3px 0" type="text" icon="el-icon-refresh" @click="getCategory"></el-button>
					</div>
                    <category-pie :bardata="categoryList" keys="category"/>
                </el-card>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6">
                <el-card class="box-card" shadow="hover">
                    <div slot="header" class="clearfix">
                        <span>专家科室分布</span>
						<el-button style="float: right; padding: 3px 0" type="text" icon="el-icon-refresh" @click="getDepartment"></el-button>
					</div>
                    <category-bar :bardata="departmentList" keys="department"/>
                </el-card>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6">
                <el-card class="box-card" shadow="hover">
                    <div slot="header" class="clearfix">
                        <span>专家科室占比</span>
						<el-button style="float: right; padding: 3px 0" type="text" icon="el-icon-refresh" @click="getDepartment"></el-button>
					</div>
                    <category-pie :bardata="departmentList" keys="department"/>
                </el-card>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6">
                <el-card class="box-card" shadow="hover">
                    <div slot="header" class="clearfix">
                        <span>专家专业分布</span>
						<el-button style="float: right; padding: 3px 0" type="text" icon="el-icon-refresh" @click="getMajor"></el-button>
					</div>
                    <category-bar :bardata="majorList" keys="major"/>
                </el-card>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6">
                <el-card class="box-card" shadow="hover">
                    <div slot="header" class="clearfix">
                        <span>专家专业占比</span>
						<el-button style="float: right; padding: 3px 0" type="text" icon="el-icon-refresh" @click="getMajor"></el-button>
					</div>
                    <category-pie :bardata="majorList" keys="major"/>
                </el-card>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6">
                <el-card class="box-card" shadow="hover">
                    <div slot="header" class="clearfix">
                        <span>专家职称分布</span>
						<el-button style="float: right; padding: 3px 0" type="text" icon="el-icon-refresh" @click="getTitle"></el-button>
					</div>
                    <category-bar :bardata="titleList" keys="title"/>
                </el-card>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6">
                <el-card class="box-card" shadow="hover">
                    <div slot="header" class="clearfix">
                        <span>专家职称占比</span>
						<el-button style="float: right; padding: 3px 0" type="text" icon="el-icon-refresh" @click="getTitle"></el-button>
					</div>
                    <category-pie :bardata="titleList" keys="title"/>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import categoryPie from './widgets/categoryPie'
    import categoryBar from './widgets/categoryBar'

    export default {
        components: {
            categoryPie,
            categoryBar
        },
        data() {
            return {
                dictList: [],
                search: {},
                categoryList: [],
                departmentList: [],
                majorList: [],
                titleList: []
            }
        },
        methods: {
            async getCategory() {
                this.loading = true
                const resp = await this.$http.get(`/statistics/expert/category`)
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.categoryList = resp.data.data
                }
                this.loading = false
            },
            async getDepartment() {
                this.loading = true
                const resp = await this.$http.get(`/statistics/expert/department`)
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.departmentList = resp.data.data
                }
                this.loading = false
            },
            async getMajor() {
                this.loading = true
                const resp = await this.$http.get(`/statistics/expert/major`)
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.majorList = resp.data.data
                }
                this.loading = false
            },
            async getTitle() {
                this.loading = true
                const resp = await this.$http.get(`/statistics/expert/title`)
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.titleList = resp.data.data
                }
                this.loading = false
            },
            async getSelect() {
                try {
                    const resp = await this.$http.get(`/dict?page=1&pageSize=500`)
                    if (!resp.data) {
                        this.$message.error(resp.data.msg)
                    } else {
                        this.dictList = resp.data.rows
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            },
            getData() {
                this.getCategory()
                this.getMajor()
                this.getDepartment()
                this.getTitle()
            }
        },
        mounted() {
            this.getData()
            // this.getSelect()

        },
    }
</script>

<style lang="scss" scoped>
    .box-card {
        margin: 10px 0;
    }
    .search {
        padding: 10px;
        background-color: #FAFAFA;
        border: 1px solid #EBEEF5;

        ::v-deep .el-form-item {
            padding: 0 !important;
            margin-bottom: 5px !important;
        }
    }
</style>
