<template>
        <div style="width:100%">
            <v-chart autoresize :option="option" style="height: 260px" v-loading="loading"></v-chart>
        </div>
</template>

<script>
export default {
    name: 'categoryPie',
    props :{
        bardata: {
            type: Array,
            default: function() {
                return []; // 返回一个空对象作为默认值
            }
        },
        keys:{
            default:""
        }
    },
    data() {
        return {
            loading: false,
            daterange: [],
            option: {
                // legend: {
                //     orient: 'vertical',
                //     x:'left', //可设定图例在左、右、居中
                //     y:'center', //可设定图例在上、下、居中
                // },
                tooltip: {
                    trigger: 'item',
                    formatter: function (obj) {
                        return obj.data.name +"<br>" +obj.data.value
                    }
                },
                series: [
                    {
                        label: {
                            normal: {
                                formatter: function (obj) {
                                    return obj.data.name
                                },
                            },
                            show: false
                        },
                        name: '',
                        type: 'pie',
                        radius: '55%',
                        center: ['50%', '50%'],
                        data: [],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            }
        }
    },
    methods: {
        async getData() {
            this.loading = true
            var that = this
            setTimeout(() => {
                var newArray = []
                that.bardata.forEach(function (item) {
                    newArray.push({name:item[that.keys],value:item.count})
                })
                that.option.series[0].data = newArray
                that.loading = false
            },500)
        }
    },
    watch: {
        bardata: {
            handler() {
                this.getData();
            },
        }
    },
    mounted() {
        this.getData()
    }
}
</script>
