<template>
    <div style="width:100%">
        <v-chart autoresize :option="option" style="height: 260px" v-loading="loading"></v-chart>
    </div>
</template>

<script>
export default {
    name: 'categoryBar',
    props :{
        bardata: {
            type: Array,
            default: function() {
                return []; // 返回一个空对象作为默认值
            }
        },
        keys:{
            default:""
        }
    },
    data() {
        return {
            loading: false,
            daterange: [],
            option: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '4%',
                    top:'3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: [],
                    axisLabel: {
                        rotate:45  // 设置旋转角度
                    }
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: '',
                        type: 'bar',
                        data: [],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            }
        }
    },
    methods: {
        async getData() {
            this.loading = true
            var that = this
            setTimeout(() => {
                const xdata = [];
                const ydata = [];
                that.bardata.forEach(function (item) {
                    xdata.push(item[that.keys])
                    ydata.push(item.count)
                })
                that.option.series[0].data = ydata
                that.option.xAxis.data = xdata
                that.loading = false
            },500)
        }
    },
    watch: {
        bardata: {
            handler() {
                this.getData();
            },
        }
    },
    mounted() {
        this.getData()
    }
}
</script>
